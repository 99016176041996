<div class="container-fuild">
  <div class="row pt-4 d-flex align-items-center">
    <div class="col-md-9 step-title">
      <h5 class="mb-0">{{inputData?.stepName}}</h5>

    </div>
    <div class="col-md-2">
      <!-- <lib-step-status-update-component-library [visaType]="inputData.visatype" [stepDetails]="stepDetails" type="EDIT"
        (onStatusToggle)="statusChangedHandler($event)">
      </lib-step-status-update-component-library> -->
      <lib-step-status-update-component-library [isViewMode]="inputData['isViewMode']"
        [isCaseRequest]="inputData['isCaseRequest']"
        [visaType]="inputData.caseType != 'I130' ? inputData.visatype : inputData.caseType" [stepDetails]="stepDetails"
        type="EDIT"></lib-step-status-update-component-library>
    </div>
    <div class="col-md-1 pr-0 d-flex justify-content-end">
      <lib-maximize-dialog-library [configData]="{'layoutCode':inputData?.layoutCode}"></lib-maximize-dialog-library>
      <i (click)="handleClose();" class="close-icon pi pi-times"></i>
    </div>
  </div>
  <div class="row">
    <div class="col-md-12 p-0">
      <!-- <lib-assigning-task-library [visaType]="inputData.visatype" [stepDetails]="stepDetails"
        (updateStatus)="updateStepStatus($event)">
      </lib-assigning-task-library> -->
      <lib-assigning-task-library [visaType]="inputData.visatype" [stepDetails]="stepDetails"
        (updateStatus)="updateStepStatus($event)" [isViewMode]="inputData['isViewMode']"
        [isCaseRequest]="beneQuestIsCaseRequest === 'true'">
      </lib-assigning-task-library>
    </div>
  </div>
  <p-divider class="header-divider"></p-divider>
  
  <div id="component-holder" *ngIf="applicantsList.length === 0 && prePDFToWorkflow === true && isFromBenQuestionairre === false"
    [ngStyle]="{'height': contHeight + 'px'}" style="position: relative;overflow-x:hidden;overflow-y: auto;">
    <div class="row sync-box" *ngIf="isShowSyncWithProfile">
      <div class="col-md-12 p-0">
        <button pButton [disabled]="disabledControls" (click)="sycProfilePitition()" type="button"
            class="p-button-rounded p-button-text pull-right syncBtn" pTooltip="Sync With Profile"
            icon="pi pi-arrow-right-arrow-left" [tooltipDisabled]="disabledControls">SYNC</button>
      </div>
    </div>
    <form [formGroup]="stepsForm">
      <div class="mr-2 ml-2 pl-4 componentAlignment" *ngFor="let data of stepFormData; let i = index;" [ngStyle]="{'min-width': data.customMinWidth , 'position' : 'absolute'
        ,'max-width':'98%', 'transform': 'translate(' + data.coordinateX + 'px, '+ data.coordinateY   + 'px)' }"
        [attr.id]="data.name">
        <div class="label-cls" *ngIf="!labelNotNeededArray.includes(data.layoutCode)">
          {{data.label}}</div>
        <app-component-loader [rendercomponent]="data.layoutCode" [sublabel]="data.subText" class="child"
          [validation]="data.mandatory" [readonly]="data.readonly" [minLength]="data.minLength"
          [maxLength]="data.maxLength" [placeHolders]="data.placeholder" #child
          [groupElementProperty]="data.groupElementProperty" [formControlName]="data.name" [value]="data.value"
          [group]="stepsForm" [groupName]="data.groupName" [inputParams]="data"
          [fileUploadConfigurations]="data.layoutCode === 'VdCustomFileUploadComponent' ? fileUploadConfigurations[data.name] : null"
          [inputData]="inputData">
        </app-component-loader>
      </div>
    </form>
  </div>
  <div id="component-holder" class="main-container post-pdf-to-workflow"
    *ngIf="(applicantsList.length === 0 && prePDFToWorkflow === false) || isFromBenQuestionairre === true"
    >
    <form [formGroup]="stepsForm">
      <div class="row sync-box" *ngIf="isShowSyncWithProfile">
        <div class="col-md-12 p-0">
          <button pButton [disabled]="disabledControls" (click)="sycProfilePitition()" type="button"
              class="p-button-text pull-right syncBtn" pTooltip="Sync With Profile"
              icon="pi pi-arrow-right-arrow-left" [tooltipDisabled]="disabledControls">SYNC</button>
        </div>
      </div>
      <div class="inner-grid-system clearfix">
        <ng-template ngFor let-data [ngForOf]="stepFormData" let-i="index">
          <div *ngIf="!data.emptyCol" class="componentAlignment"
            [ngClass]="{'leftAlign': data.align == 'left', 'col-4': data.numberOfColumn && data.numberOfColumn === 4, 'col-6': data.numberOfColumn && data.numberOfColumn === 6, 'col-8': data.numberOfColumn && data.numberOfColumn === 8, 'col-12': data.numberOfColumn && data.numberOfColumn === 12, 'vd-drag-box-padding': !data.numberOfColumn}"
            [ngStyle]="{'top': data.coordinateY + 'px', 'left': data.coordinateX + 'px' }" [attr.id]="data.name">
            <div class="label-cls" *ngIf="!labelNotNeededArray.includes(data.layoutCode)">
              {{data.label}}</div>
            <app-component-loader [rendercomponent]="data.layoutCode" [sublabel]="data.subText" class="child"
              [validation]="data.mandatory" [readonly]="data.readonly" [minLength]="data.minLength"
              [maxLength]="data.maxLength" [placeHolders]="data.placeholder" #child
              [groupElementProperty]="data.groupElementProperty" [formControlName]="data.name" [value]="data.value"
              [group]="stepsForm" [groupName]="data.groupName" [inputParams]="data"
              [fileUploadConfigurations]="data.layoutCode === 'VdCustomFileUploadComponent' ? fileUploadConfigurations[data.name] : null"
              [inputData]="inputData">
            </app-component-loader>

          </div>
          <div *ngIf="data.emptyCol" class="clearfix"></div>
        </ng-template>
      </div>
    </form>
  </div>
  <div id="component-holder" *ngIf="applicantsList.length > 0"
    [ngStyle]="{'height': contHeight + 'px'}" style="position: relative; overflow-x:hidden; overflow-y: auto; padding-bottom: 1px;">
    <p-tabView styleClass="tabview-custom applicant-view" [(activeIndex)]="activeIndex"
      (onChange)="handleChange($event)">
      <!-- <form formArrayName="stepsFormArray" (ngSubmit)="postStepsForm()"> -->
      <p-tabPanel *ngFor="let applicant of applicantsList; let i=index;">
        <ng-template pTemplate="header">
          <span>{{applicant.firstName}} {{applicant.lastName}}</span><br>
          <span class="relation"> - {{applicant?.relationshipName}}</span>
        </ng-template>
        <ng-template pTemplate="content">
          <!-- <ng-container *ngFor="let stepFrm of stepsFormArray.controls; let k = index"> -->
          <div [formGroup]="applicant.formGroup">
            <div *ngIf="prePDFToWorkflow === true">
              <div class="mr-2 ml-2 pl-4 componentAlignment" *ngFor="let data of applicant.stepForm; let i = index;"
                [ngStyle]="{'min-width': data.customMinWidth, 'position': 'absolute','max-width':'98%', 'transform': 'translate(' + data.coordinateX + 'px, '+ data.coordinateY   + 'px)' }"
                [attr.id]="data.name">
                <div class="label-cls" *ngIf="!labelNotNeededArray.includes(data.layoutCode)">
                  {{data.label}}</div>
                <app-component-loader [rendercomponent]="data.layoutCode" [sublabel]="data.subText" class="child"
                  [validation]="data.mandatory" [readonly]="data.readonly" [minLength]="data.minLength"
                  [maxLength]="data.maxLength" [placeHolders]="data.placeholder" #child
                  [groupElementProperty]="data.groupElementProperty" [formControlName]="data.name" [value]="data.value"
                  [group]="applicant.formGroup" [groupName]="data.groupName" [inputParams]="data"
                  [fileUploadConfigurations]="data.layoutCode === 'VdCustomFileUploadComponent' ? fileUploadConfigurations[data.name] : null"
                  [inputData]="inputData">
                </app-component-loader>
              </div>
            </div>
            <div class="post-pdf-to-workflow clearfix" *ngIf="prePDFToWorkflow === false">
              <ng-template ngFor let-data [ngForOf]="applicant.stepForm" let-i="index">
                <div *ngIf="!data.emptyCol" class="componentAlignment"
                  [ngClass]="{'leftAlign': data.align == 'left', 'col-4': data.numberOfColumn && data.numberOfColumn === 4, 'col-6': data.numberOfColumn && data.numberOfColumn === 6, 'col-8': data.numberOfColumn && data.numberOfColumn === 8, 'col-12': data.numberOfColumn && data.numberOfColumn === 12, 'vd-drag-box-padding': !data.numberOfColumn}"
                  [ngStyle]="{'top': data.coordinateY + 'px', 'left': data.coordinateX+'px' }" [attr.id]="data.name">
                  <div class="label-cls" *ngIf="!labelNotNeededArray.includes(data.layoutCode)">
                    {{data.label}}</div>
                  <app-component-loader [rendercomponent]="data.layoutCode" [sublabel]="data.subText" class="child"
                    [validation]="data.mandatory" [readonly]="data.readonly" [minLength]="data.minLength"
                    [maxLength]="data.maxLength" [placeHolders]="data.placeholder" #child
                    [groupElementProperty]="data.groupElementProperty" [formControlName]="data.name"
                    [value]="data.value" [group]="applicant.formGroup" [groupName]="data.groupName" [inputParams]="data"
                    [fileUploadConfigurations]="data.layoutCode === 'VdCustomFileUploadComponent' ? fileUploadConfigurations[data.name] : null"
                    [inputData]="inputData">
                  </app-component-loader>
                </div>
                <div *ngIf="data.emptyCol" class="clearfix"></div>
              </ng-template>
            </div>
          </div>
        </ng-template>
      </p-tabPanel>
    </p-tabView>
  </div>
  <!-- <p-divider></p-divider> -->
  <!-- pending UI verification -->
  <div class="w-100 pt-3 mt-n3">
    <lib-custom-questionnaire [stepId]="inputData.stepId" [taskId]="inputData.taskId" [viewQuestions]="false"
      [showSaveBtn]="false" [loadedIn]="stepDetails.stepName" [isSection]="false"></lib-custom-questionnaire>
  </div>
  <p-divider type="dashed" class="dividerAlign"></p-divider>
  <div class="row pt-3">
    <div class="col-md-2">
      <button pButton label="Save" title="Save" class="save-btn" icon="pi pi-save"
        [ngClass]="stepsForm.invalid  ? 'btn-success button-width-icon-left mr-3 btn-success_disabled':'btn-success button-width-icon-left mr-3'"
        [disabled]="stepsForm.invalid || external"
        (click)="(applicantsList.length === 0) ? postStepsForm() : postApplicantsStepsForm()"></button>
    </div>
    <div class="col-md-2">
      <button pButton type="button" label="Cancel" title="Cancel" icon="pi pi-times-circle" class="p-button-secondary cancel-btn button-width-icon-left
                d-flex alogn-items-center" [disabled]="external" (click)="handleCancel()">
      </button>
    </div>
    <div class="col-md-2"></div>
    <div class="col-md-6">
      <lib-step-status-update-component-library [visaType]="inputData.visatype" [stepDetails]="stepDetails"
        [type]="typeOfButton" (onStatusToggle)="statusChangedHandler($event)">
      </lib-step-status-update-component-library>
    </div>
  </div>

</div>
